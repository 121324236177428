import { useEffect, useState } from "react";
// import leagueTableData from "../../constants/leagueTableData";
import mainLeagueTableData from "../../constants/mainLeagueTableData";
import axiosRequest from "../../helpers/axios";
import shortClubName from "../../helpers/shortClubName";

const LeagueTableMain = () => {
  const [standings, setStandings] = useState(null);

  useEffect(() => {
    axiosRequest("/league-tables/?_sort=Points:DESC,Matches:DESC").then(
      (response) => setStandings(response.data)
    );
  }, []);

  return (
    <div className="w-100 h-100 justify-content-between">
      <div className="fixture-title text-uppercase text-center text-primary bold py-2">
        <h3 className="text-start mb-2 mb-md-3">
          <small className="text-primary fw-bolder text-capitalize">
            League Table
          </small>
        </h3>
      </div>
      <div className="d-flex justify-content-between flex-column">
        {standings && (
          <table className="table">
            <thead className="bg-primary text-white border border-0">
              <tr className="text-uppercase">
                {mainLeagueTableData.theadMin.map((data) => (
                  <th>{data.toString()}</th>
                ))}
              </tr>
            </thead>
            <tbody className="text-primary custom-tbody">
              {standings.map((standing, index) => (
                <tr
                  className={
                    shortClubName(standing.club.Name) === "BLFC"
                      ? "table-active"
                      : ""
                  }
                  key={standing.club.hash}
                >
                  <td>{index + 1}</td>
                  <td>
                    <span className="d-flex m-0 p-0">
                      <span className="mx-2">
                        <img
                          className="table-img rounded-circle"
                          src={
                            process.env.REACT_APP_API_URL +
                            standing.club.Logo.url
                          }
                          alt={standing.club.name}
                        />
                      </span>
                      <span className="mx-2">
                        <p className="m-0">
                          {shortClubName(standing.club.Name)}
                        </p>
                      </span>
                    </span>
                  </td>
                  <td>{standing.Matches}</td>
                  <td>{standing.Win}</td>
                  <td>{standing.Loose}</td>
                  <td>{standing.Draw}</td>
                  <td>{standing.Goal_Scored}</td>
                  <td>{standing.Goal_Difference}</td>
                  <td>{standing.Points}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default LeagueTableMain;
