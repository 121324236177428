import FacebookSvg from "../Svg/FacebookSvg";
import InstaSvg from "../Svg/InstaSvg";
import TwitterSvg from "../Svg/TwitterSvg";

const SocialMedia = () => {
  return (
    <div className="w-100 text-center mt-3">
      {/* <label className="text-uppercase nunito neutral">
        BLFC on Social Media on
      </label>
      <div className="text-white text-center mt-3 mb-4">
        <FacebookSvg
          className="rounded-2 mx-2 head-icon cursor-pointer"
          activeColor="red"
          color="#1877F2"
          width={24}
          onClick={() =>
            window.open("https://www.facebook.com/Butwallumbinifc")
          }
        />

        <TwitterSvg
          className="rounded-2 mx-4 head-icon cursor-pointer"
          activeColor="red"
          color="#1877F2"
          width={24}
          onClick={() => {}}
        />

        <InstaSvg
          className="rounded-2 mx-2 head-icon cursor-pointer"
          activeColor="red"
          color="#1877F2"
          width={24}
          onClick={() =>
            window.open("https://www.instagram.com/butwal_lumbinifc/")
          }
        />
      </div> */}
      <div>
        <img
          className="footer-image"
          src="https://i.ibb.co/yW5mKJK/loloo.png"
          alt="advertisment"
        />
      </div>
      <div className="foot">
        <div className="text-center text-white overflow-hidden px-5 mx-md-2">
          <div className="row flex-column flex-md-row">
            <div className="col-md-4 text-center text-md-start neutral mt-4">
              <p className="fw-bold text-white pt-3">
                Copyright @2021 | All Rights Reserved
              </p>
            </div>
            <div className="col-md-4">
              <label className="text-uppercase text-white">
                BLFC on Social Media
              </label>
              <div className="text-white text-center mt-3 mb-4">
                <FacebookSvg
                  className="rounded-2 mx-2 head-icon cursor-pointer"
                  activeColor="red"
                  color="#1877F2"
                  width={24}
                  onClick={() =>
                    window.open("https://www.facebook.com/Butwallumbinifc")
                  }
                />

                <TwitterSvg
                  className="rounded-2 mx-4 head-icon cursor-pointer"
                  activeColor="red"
                  color="#1877F2"
                  width={24}
                  onClick={() => window.open("https://twitter.com/blfcnepal")}
                />

                <InstaSvg
                  className="rounded-2 mx-2 head-icon cursor-pointer"
                  activeColor="red"
                  color="#1877F2"
                  width={24}
                  onClick={() =>
                    window.open("https://www.instagram.com/butwal_lumbinifc/")
                  }
                />
              </div>
            </div>
            <div className="col-md-4 text-center text-md-end neutral mt-4">
              <p className="fw-bold text-white pt-3">
                Created by:{" "}
                <a
                  className="fw-normal text-white"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.quickfoxconsulting.com/"
                >
                  Quickfox Consulting
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* <hr className="opacity-100 px-5 mx-5 footer-divider neutral-bg" />
      <div className="text-center text-white overflow-hidden px-5 mx-md-2">
        <div className="row flex-column flex-md-row">
          <div className="col-md-6 text-center text-md-start neutral">
            <p className="fw-bold">Copyright @2021 | All Rights Reserved</p>
          </div>
          <div className="col-md-6 text-center text-md-end neutral">
            <p className="fw-bold">
              Created by:{" "}
              <a
                className="fw-normal"
                target="_blank"
                rel="noreferrer"
                href="https://www.quickfoxconsulting.com/"
              >
                Quickfox Consulting
              </a>
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default SocialMedia;
