import { useField } from "formik";
import ErrorLabel from "./misc/errorLabel";

const SelectInput = ({ items, label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="d-flex flex-column my-2">
      <label className="neutral my-2" htmlFor={field.name}>
        {label} {props.required && " *"}
      </label>
      <select
        className="form-select custom-form-control"
        aria-label="Default select example"
        {...props}
        {...field}
      >
        <option value="">Select a {label}</option>
        {items.map((item) => (
          <option value={item.key} key={item.key}>
            {item.value}
          </option>
        ))}
      </select>
      {meta.error && <ErrorLabel message={meta.error} />}
    </div>
  );
};

export default SelectInput;
