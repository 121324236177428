import lookup from "country-code-lookup";
import splitPosition from "../../helpers/splitPosition";

const OfficialProfile = ({ team }) => {
  return (
    <div
      className="card col-sm-6 col-md-4 col-lg-3 shadow-0 border-0 rounded-0  team-profile-wrapper mt-2"
      key={team.Avatar.hash}
    >
      <div className="position-relative team-content">
        <img
          src={process.env.REACT_APP_API_URL + team.Avatar.url}
          className="card-img-top team-img border-0 team-profile-img"
          alt="goalkeeper"
        />
        <div className="overlay-team-profile w-100 position-absolute text-white text-center">
          <div className="team-profile-extra d-flex align-items-center justify-content-center flex-column h-100">
            <p>Pos: {splitPosition(team.Position)}</p>
            <p>DOB: {team.Date_of_Birth}</p>
            <p>Native: {lookup.byInternet(team.Country).country}</p>
          </div>
        </div>
      </div>
      <div className="row text-center w-100 d-flex team-profile-details m-0 p-0 w-100">
        {/* <div className="col-3 bg-primary d-flex justify-content-center align-items-center py-2 position-relative h-100">
          <h5 className="card-title d-inline text-white fs-md-5 fs-4 arrow-right z-4 team-jersy">
            {team.Jersey_Number}
          </h5>
        </div> */}
        <div className="col-12 bg-secondary d-flex justify-content-center align-items-center ps-4 py-2">
          <p className="card-title d-inline text-white fs-6">{team.Name}</p>
        </div>
      </div>
    </div>
  );
};

export default OfficialProfile;
