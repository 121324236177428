import { TrashIcon } from "@heroicons/react/outline";
import { Field, FieldArray } from "formik";
import { toast } from "react-toastify";
import ErrorLabel from "./misc/errorLabel";

const YoutubeLink = ({ values, errors }) => {
  return (
    <div>
      <FieldArray
        name="Videos"
        render={(arrayHelpers) => (
          <div className="row">
            {values.Videos.map((value, index) => (
              <div>
                <div
                  key={index}
                  className="input-group my-2 d-flex align-items-center justify-content-between"
                >
                  <div className="col">
                    <label htmlFor={`Videos.${index}.Link`}>
                      Video Link to Share
                    </label>
                    <Field
                      type="text"
                      className="form-control custom-form-control me-3"
                      name={`Videos.${index}.Link`}
                    />
                  </div>
                  <div className="col-1">
                    <label className="d-block"></label>
                    <div className="d-flex my-1 mt-4 align-content-center justify-content-end">
                      <TrashIcon
                        className="d-block"
                        width={25}
                        onClick={() => arrayHelpers.remove(index)}
                      />
                    </div>
                  </div>
                </div>
                {errors.Videos?.[index] && (
                  <ErrorLabel message={errors.Videos[index]["Link"]} />
                )}
              </div>
            ))}
            <div className="d-flex align-items-center justify-content-start">
              <label
                className="text-secondary d-flex align-items-center justify-content-around cursor-pointer"
                onClick={() =>
                  values.Videos.length < 3
                    ? arrayHelpers.push({
                        Link: "",
                      })
                    : toast.info("Max 3 Videos can be Added")
                }
              >
                <div className="mx-2 cursor-pointer">
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.4717 3.69434H5.47168C4.36711 3.69434 3.47168 4.58977 3.47168 5.69434V19.6943C3.47168 20.7989 4.36711 21.6943 5.47168 21.6943H19.4717C20.5763 21.6943 21.4717 20.7989 21.4717 19.6943V5.69434C21.4717 4.58977 20.5763 3.69434 19.4717 3.69434Z"
                      stroke="black"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.4717 8.69434V16.6943"
                      stroke="black"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.47168 12.6943H16.4717"
                      stroke="black"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                Add Link
              </label>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default YoutubeLink;
