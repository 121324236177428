import axiosRequest from "./axios";

const lastMatch = async () => {
  const matches = (await axiosRequest("/matches/?_sort=id:ASC")).data;
  const filter = matches.filter(
    (matches) =>
      +new Date(matches.Fixture.Date_Time) < +Date.now() &&
      (matches.Fixture.Home_Club.Name === "Butwal Lumbini F C" ||
        matches.Fixture.Away_Club.Name === "Butwal Lumbini F C")
  );
  return filter;
};
export default lastMatch;
