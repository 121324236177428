import { TrashIcon } from "@heroicons/react/outline";
import { Field, FieldArray } from "formik";
import { toast } from "react-toastify";
import countriesList from "../../constants/countries";
import ErrorLabel from "./misc/errorLabel";

const HistoryClub = ({ values, errors }) => {
  return (
    <div>
      <FieldArray
        name="History"
        render={(arrayHelpers) => (
          <div className="row">
            {values.History.map((value, index) => (
              <div
                key={index}
                className="input-group my-2 d-flex align-items-center justify-content-between"
              >
                <div className="col-12 col-sm-4">
                  <label htmlFor={`History.${index}.Previous_Club`}>
                    Previous Club
                  </label>
                  <Field
                    type="text"
                    className="form-control custom-form-control me-3"
                    name={`History.${index}.Previous_Club`}
                  />
                  {errors.History?.[index] && (
                    <ErrorLabel
                      message={errors.History[index]["Previous_Club"]}
                    />
                  )}
                </div>
                <div className="col-12 col-sm-4 ms-lg-3">
                  <label htmlFor={`History.${index}.Location`}>Country</label>
                  <Field
                    as="select"
                    name={`History.${index}.Location`}
                    className="form-control custom-form-control"
                  >
                    <option value="">Select a Country</option>
                    {countriesList.map((country, index) => (
                      <option value={country.code} key={country.code}>
                        {country.name}
                      </option>
                    ))}
                  </Field>
                  {errors.History?.[index] && (
                    <ErrorLabel message={errors.History[index]["Location"]} />
                  )}
                </div>
                <div className="col-12 col-sm-2 ms-lg-3">
                  <label htmlFor={`History[${index}].Period`}>Year</label>
                  <Field
                    type="text"
                    className="form-control custom-form-control"
                    name={`History[${index}].Period`}
                  />
                  {errors.History?.[index] && (
                    <ErrorLabel message={errors.History[index]["Period"]} />
                  )}
                </div>

                <div className="col-12 col-sm-1 d-flex justify-content-end">
                  <label className="d-block"></label>
                  <div className="d-flex my-1 mt-4">
                    <TrashIcon
                      className="d-block cursor-pointer"
                      width={25}
                      onClick={() =>
                        values.length > 1
                          ? arrayHelpers.remove(index)
                          : () => {}
                      }
                    />
                  </div>
                </div>
              </div>
            ))}
            <div className="d-flex align-items-center justify-content-start">
              <label
                className="text-secondary d-flex align-items-center justify-content-around cursor-pointer"
                onClick={() =>
                  values.History.length < 4
                    ? arrayHelpers.push({
                        Previous_Club: "",
                        Location: "",
                        Period: "",
                      })
                    : toast.info("Max 4 items can be added")
                }
              >
                <div className="mx-2 cursor-pointer">
                  <svg
                    width={20}
                    height={20}
                    viewBox="0 0 25 25"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M19.4717 3.69434H5.47168C4.36711 3.69434 3.47168 4.58977 3.47168 5.69434V19.6943C3.47168 20.7989 4.36711 21.6943 5.47168 21.6943H19.4717C20.5763 21.6943 21.4717 20.7989 21.4717 19.6943V5.69434C21.4717 4.58977 20.5763 3.69434 19.4717 3.69434Z"
                      stroke="black"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M12.4717 8.69434V16.6943"
                      stroke="black"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M8.47168 12.6943H16.4717"
                      stroke="black"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                Add Club Details
              </label>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default HistoryClub;
