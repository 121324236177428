import React, { useState, useEffect } from "react";
import axiosRequest from "../../helpers/axios";
import moment from "moment";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Gallery() {
  const [data, setData] = useState(null);
  const [ads, setAds] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    axiosRequest("/galleries?&_sort=id:DESC").then((response) => {
      setData(response.data);
    });
    axiosRequest("/advertisments").then((response) => {
      setAds(response.data);
    });
    // eslint-disable-next-line
  }, []);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          initialSlide: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div>
        {data && (
          <img
            src="https://scontent.fktm8-1.fna.fbcdn.net/v/t1.6435-9/178666274_125930632904429_8395905666846507042_n.jpg?_nc_cat=106&ccb=1-5&_nc_sid=e3f864&_nc_ohc=W3cYOww8xJAAX8AYGl1&_nc_ht=scontent.fktm8-1.fna&oh=00_AT-VSPOqBi0PQbKDB0tXkt17xMPKKFy9fb-GobgyQcoSVQ&oe=62348850"
            alt="Latest images and videos"
            className="img-responsive main-image-resize pt-3"
            width="100%"
          />
        )}
      </div>

      {/* latest videos */}
      <div className="container mb-5">
        <h2 className="text-primary mt-4 fs-3 fw-bold">Latest videos</h2>
        <Slider {...settings}>
          {data &&
            data
              .filter((videos) => videos.Videos.length !== 0)
              .map((videos) => (
                <div
                  className="col-md-4 col-sm-6 px-3 hover-zoomin"
                  key={videos.id}
                >
                  <Link
                    to={{
                      pathname: "/gallery/" + videos.id,
                    }}
                    key={videos.Featured_Image.hash}
                  >
                    <div className="card shadow mb-5 bg-body rounded">
                      <div className="hover-zoomin">
                        <span className="overlay-darken">
                          <img
                            src={
                              process.env.REACT_APP_API_URL +
                              videos.Featured_Image.url
                            }
                            className="card-img-top gallary-image"
                            alt="Latest Videos"
                          />
                        </span>
                        <h2 className="image-count-overlay text-white">
                          +{videos.Videos.length}
                        </h2>
                      </div>
                      <div className="card-body">
                        <p className="card-text gallary-date-size mb-0">
                          {moment(videos.published_at).format("Do MMM, YYYY")}
                        </p>
                        <h2 className="card-text mt-2 gallary-text-size text-primary gallery-text-wrap">
                          {videos.Title}
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
        </Slider>
      </div>
      {/* advertisment */}
      {ads &&
        ads
          .filter((ads) => ads.section === "GallerySection")
          .map((ads) => (
            <div
              className="container px-sm-5 d-flex justify-content-center"
              key={ads.id}
            >
              <img
                className="img-responsive gallary-adv-image"
                src={
                  process.env.REACT_APP_API_URL + ads.Ads[0].advertisment.url
                }
                alt=""
                width="100%"
              />
            </div>
          ))}

      {/* latest Images */}
      <div className="container mb-5">
        <h2 className="text-primary mt-4 fs-3 fw-bold">Latest Images</h2>
        <Slider {...settings}>
          {data &&
            data
              .filter((images) => images.Content_Image.length !== 0)
              .map((image) => (
                <div
                  className="col-md-4 col-sm-6 px-3 hover-zoomin"
                  key={image.id}
                >
                  <Link
                    to={{
                      pathname: "/gallery/" + image.id,
                    }}
                    key={image.Featured_Image.hash}
                  >
                    <div className="card shadow mb-5 bg-body rounded">
                      <div className="hover-zoomin">
                        <span className="overlay-darken">
                          <img
                            src={
                              process.env.REACT_APP_API_URL +
                              image.Featured_Image.url
                            }
                            className="card-img-top gallary-image"
                            alt="Latest News"
                          />
                        </span>
                        <h2 className="image-count-overlay text-white">
                          +{image.Content_Image.length}
                        </h2>
                      </div>
                      <div className="card-body">
                        <p className="card-text gallary-date-size mb-0">
                          {moment(image.published_at).format("Do MMM, YYYY")}
                        </p>
                        <h2 className="card-text mt-2 gallary-text-size text-primary gallery-text-wrap">
                          {image.Title}
                        </h2>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
        </Slider>
      </div>
    </div>
  );
}

export default Gallery;
