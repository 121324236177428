import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import nextMatch from "../../helpers/nextMatch";
import ArrowRight from "../Svg/ArrowRight";
import moment from "moment";

const FixtureBanner = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    (async function getNextMatch() {
      setData(await nextMatch());
    })();
  }, []);

  return data !== null && data !== undefined ? (
    <div className="fixture-container container d-flex flex-column justify-content-around align-items-center">
      <div className="fixture-title text-uppercase text-center text-primary bold py-2">
        <h3 className="text-start mb-2 mb-md-3">
          <small className="text-primary fw-bolder text-capitalize">
            Next Match
          </small>
        </h3>
      </div>
      <div className="fixture-subtitle text-center fw-bold fs-5 mb-md-2">
        {data && (
          <p>
            {moment(data.Date_Time).format("Do MMM")} |{" "}
            {moment(data.Date_Time).format("dddd")} |{" "}
            {moment(data.Date_Time).format("h:mm a")} | {data.Venue.Name}
          </p>
        )}
      </div>
      <div className="row w-100 px-md-2">
        <div className="col-md-4">
          {data && <ClubContainer data={data.Home} />}
        </div>
        <div className="col-md-4 d-flex align-items-center justify-content-around flex-column px-md-5 px-lg-1">
          <div className="text-center">
            <h1 className="p-1 p-md-3 text-black text-center">VS</h1>
          </div>
        </div>
        <div className="col-md-4">
          {data && <ClubContainer data={data.Away} />}
        </div>
      </div>
      <div className="w-100 text-center mt-3">
        <span>
          <Link className="view-more" to="/fixtures">
            View More
          </Link>
          <ArrowRight />
        </span>
      </div>
    </div>
  ) : (
    // <div className="card h-100 shadow p-2">
    <div>
      <h2 className="text-primary fs-3 fw-bold mt-3">Next Match</h2>
      <div className="d-flex justify-content-center mb-4">
        <img
          className="comming-soon"
          src="https://image.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg"
          alt="Coming up"
        />
      </div>
    </div>
    // <div className="fixture-container container d-flex flex-column justify-content-around align-items-center">
    //   <div className="fixture-title text-uppercase text-center text-primary bold py-2">
    //     <h3 className="text-start mb-2 mb-md-3">
    //       <small className="text-primary fw-bolder text-capitalize">
    //         Next Match
    //       </small>
    //     </h3>
    //   </div>
    //   <div className="fixture-subtitle text-center fw-bold fs-5 mb-md-2"></div>
    //   <div className="row w-100 px-md-2">
    //     <div className="col-md-4">
    //       <img
    //         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdxXTJfjkJRIYLXuESrhcWOZFpV6b27WQFoXKXWMqxs_7X2HNR5b9h93oNkWszI6uNj2k&usqp=CAU"
    //         alt="dummy"
    //         height="260px"
    //         width="100%"
    //       />
    //     </div>
    //     <div className="col-md-4 d-flex align-items-center justify-content-around flex-column px-md-5 px-lg-1">
    //       <div className="text-center">
    //         <h1 className="p-1 p-md-3 text-black text-center">VS</h1>
    //       </div>
    //     </div>
    //     <div className="col-md-4">
    //       <img
    //         src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTdxXTJfjkJRIYLXuESrhcWOZFpV6b27WQFoXKXWMqxs_7X2HNR5b9h93oNkWszI6uNj2k&usqp=CAU"
    //         alt="dummy"
    //         height="260px"
    //         width="100%"
    //       />
    //     </div>
    //   </div>
    // </div>
  );
};

const ClubContainer = ({ data }) => {
  return (
    <div className="row w-100 d-flex flex-column align-items-center justify-content-center py-3 py-md-0">
      <img
        className="w-100 rounded-circle px-2"
        src={process.env.REACT_APP_API_URL + data.Logo.url}
        alt=""
        height="129"
      />
      <p className="fw-bold text-center my-2 my-md-3 text-uppercase">
        {data.Name}
      </p>
    </div>
  );
};

export default FixtureBanner;
