import { useEffect, useState } from "react";
import axiosRequest from "../../helpers/axios";

const Sponsers = () => {
  const [sponsors, setSponsors] = useState([]);
  useEffect(() => {
    axiosRequest("/sponsors?_sort=id:ASC").then((response) => {
      setSponsors(response.data);
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div className="sponsor-container d-flex flex-column align-items-center">
      <div className="title-sponsor text-center neutral pt-1 pb-md-0">
        <h2>Sponsors</h2>
      </div>
      <div className="d-flex flex-wrap align-items-center justify-content-around p-4 py-0 mx-md-5 sponsor-clearfix">
        {sponsors.map((sponsor) => (
          <div
            className="d-flex align-items-center justify-content-center overflow-hidden p-0 my-md-0 my-3 col-sm-6 col-md-4 col-lg-4 px-1 my-md-1 px-2"
            key={sponsor.Logo.hash}
          >
            <img
              className="sposnor-img m-0 px-2 px-md-2 my-md-0 my-1 my-md-2"
              src={process.env.REACT_APP_API_URL + sponsor.Logo.url}
              alt=""
              height="60px"
            />
          </div>
        ))}
      </div>
      <div>
        <img
          className="img-fluid img-responsive sponsor-title my-2"
          src="/images/quickfox.png"
          alt="quickfox"
        />
      </div>
    </div>
  );
};

export default Sponsers;
