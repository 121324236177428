import { Field, useField } from "formik";
import countriesList from "../../constants/countries";
import ErrorLabel from "./misc/errorLabel";

const CountryPicker = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="d-flex flex-column my-2">
      <label className="neutral my-2" htmlFor={field.name}>
        {label} {props.required && " *"}
      </label>
      <Field
        as="select"
        {...field}
        className="form-control custom-form-control"
      >
        <option value="">Select a Country</option>
        {countriesList.map((country, index) => (
          <option value={country.code} key={country.code + country.name}>
            {country.name}
          </option>
        ))}
      </Field>
      {meta.error && <ErrorLabel message={meta.error} />}
    </div>
  );
};

export default CountryPicker;
