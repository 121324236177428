import { Form, Formik } from "formik";
import TextArea from "../../../components/InputForms/TextArea";
import TextField from "../../../components/InputForms/TextField";
import InitialValueForm from "../../../constants/initialValueForm";
import contactUsValidations from "../../../validations/contactUsValidation";
import SelectInput from "../../../components/InputForms/SelectInput";
import SubmitButton from "../../../components/Buttons/SubmitButton";
import formHelper from "../../../helpers/formHelper";

const SponsorForm = () => {
  const handleSubmit = (values, { setSubmitting, resetForm }) =>
    formHelper({ values, setSubmitting, resetForm, url: "promotions" });

  return (
    <Formik
      initialValues={InitialValueForm.sponsor}
      validationSchema={contactUsValidations.sponsors}
      onSubmit={handleSubmit}
    >
      {(formik) => (
        <>
          <Form>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-lg-6">
                    <TextField
                      type="text"
                      name="Name_of_Company"
                      label="Name of Company"
                      required={false}
                    />
                  </div>
                  <div className="col-lg-6">
                    <TextField
                      type="text"
                      name="Contact_Person_Name"
                      label="Contact Person Name"
                      required={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <TextField
                      type="text"
                      name="Designation"
                      label="Designation"
                      required={false}
                    />
                  </div>
                  <div className="col-lg-6">
                    <TextField
                      type="number"
                      name="Cell_Phone_NUmber"
                      label="Cell Phone Number"
                      required={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <TextField
                      type="email"
                      name="Email_Id"
                      label="Email"
                      required={false}
                    />
                  </div>
                  <div className="col-lg-6">
                    <SelectInput
                      items={InitialValueForm.Period_Sponsor}
                      label="Period"
                      name="Period"
                      required={false}
                    />
                  </div>
                </div>

                <TextArea name="Remarks" label="Remarks" required={false} />
              </div>
              <div>
                <SubmitButton
                  type="submit"
                  label="Send"
                  disabled={formik.isSubmitting}
                />
              </div>
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};
export default SponsorForm;
