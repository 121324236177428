import { useField } from "formik";
import ErrorLabel from "./misc/errorLabel";

const DatePickerField = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="d-flex flex-column my-2">
      <label className="neutral my-2" htmlFor={field.name}>
        {label} {props.required && " *"}
      </label>
      <input
        type="date"
        className="form-control custom-form-control"
        {...props}
        {...field}
        autoComplete="off"
      />
      {meta.error && <ErrorLabel message={meta.error} />}
    </div>
  );
};

export default DatePickerField;
