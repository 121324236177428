import { Form, Formik } from "formik";
import CountryPicker from "../../../components/InputForms/CountryPicker";
import DatePickerField from "../../../components/InputForms/DatePicker";
import SelectInput from "../../../components/InputForms/SelectInput";
import TextArea from "../../../components/InputForms/TextArea";
import TextField from "../../../components/InputForms/TextField";
import UploadInput from "../../../components/InputForms/UploadInput";
import InitialValueForm from "../../../constants/initialValueForm";
import { useState } from "react";
import uploadFile from "../../../hooks/uploadFile";
import HistoryClub from "../../../components/InputForms/HistoryClub";
import YoutubeLink from "../../../components/InputForms/YoutubeLinks";
import contactUsValidations from "../../../validations/contactUsValidation";
import { toast } from "react-toastify";
import formHelper from "../../../helpers/formHelper";
import SubmitButton from "../../../components/Buttons/SubmitButton";

const ForeignPlayerForm = () => {
  const [fileData, setFileData] = useState({ loading: false, file: null });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (!fileData.file) {
      toast.info("Please select an Image");
    } else {
      const uploadedData = await uploadFile({ file: fileData.file });
      values["Recent_Photo"] = uploadedData[0].id;
      formHelper({ values, setSubmitting, resetForm, url: "foreign-players" });
    }
  };

  return (
    <Formik
      initialValues={InitialValueForm.foreignPlayer}
      validationSchema={contactUsValidations.foreignPlayer}
      onSubmit={handleSubmit}
    >
      {({ handleChange, values, errors, isSubmitting }) => (
        <>
          <Form>
            <div className="row">
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <TextField
                      type="text"
                      name="Name"
                      label="Name"
                      required={false}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <TextField
                      type="text"
                      name="Current_Address"
                      label="Current Address"
                      required={false}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-6">
                    <TextField
                      type="number"
                      name="Weight"
                      label="Weight (in kg)"
                      required={false}
                    />
                  </div>
                  <div className="col-6">
                    <TextField
                      type="number"
                      name="Height"
                      label="Height (in cm)"
                      required={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <TextField
                      type="text"
                      name="Current_Club"
                      label="Current Club Name"
                      required={false}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <TextField
                      type="number"
                      name="Expected_Salary"
                      label="Expected Salary"
                      prefix="USD"
                      required={false}
                    />
                  </div>
                </div>

                <HistoryClub values={values} errors={errors} />

                <UploadInput
                  id="Recent_Photo"
                  name="Recent_Photo"
                  label="Recent Photo"
                  file={fileData.file}
                  required={false}
                  onChange={(event) => {
                    handleChange("Recent_Photo", event);
                    // formik.setFieldValue(
                    //   "Recent_Photo",
                    //   event.currentTarget.files[0]
                    // );
                    setFileData({ file: event.target.files[0] });
                  }}
                  // onChange={(event) => {
                  //   setFileData({ file: event.target.files[0] });
                  //   // console.log(fileData);
                  //   // formik.handleChange("Recent_Photo");
                  // }}
                />
              </div>
              <div className="col-lg-6">
                <div className="row">
                  <div className="col-6">
                    <DatePickerField
                      name="Date_of_Birth"
                      data-date-format="mm/dd/yyyy"
                      label="DOB"
                      required={false}
                    />
                  </div>
                  <div className="col-6">
                    <CountryPicker
                      name="Country"
                      label="Country"
                      required={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-6">
                    <TextField
                      type="email"
                      name="Email"
                      label="Email"
                      required={false}
                    />
                  </div>

                  <div className="col-12 col-md-6">
                    <TextField
                      type="number"
                      name="Contact_No"
                      label="Phone Number"
                      required={false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-6">
                    <SelectInput
                      items={InitialValueForm.preferedPosition}
                      label="Preferred Position"
                      name="Preferred_Position"
                      required={false}
                    />
                  </div>
                  <div className="col-6">
                    <SelectInput
                      items={InitialValueForm.secondPreferedPosition}
                      label="Second Position"
                      name="Second_Preferred_Position"
                      required={false}
                    />
                  </div>
                </div>
                <YoutubeLink values={values} errors={errors} />
              </div>
            </div>
            <div>
              <div className="row">
                <div className=" col-12 col-lg-6">
                  <TextArea name="Remarks" label="Remarks" required={false} />
                </div>
              </div>
              <SubmitButton label="Send" disabled={isSubmitting} />
            </div>
          </Form>
        </>
      )}
    </Formik>
  );
};

export default ForeignPlayerForm;
