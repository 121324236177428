import * as Yup from "yup";

const contactUsValidations = {
  technicalTeam: Yup.object().shape({
    Category: Yup.string().required("Required"),
    Name: Yup.string()
      .required("Name is Required")
      .max(25, "Name shouldn't be max than 25 Characters"),
    Weight: Yup.number().required("Weight Required").positive().integer(),
    Height: Yup.number().required("Height Required").positive().integer(),
    Date_of_Birth: Yup.date().required("Date of Birth Required"),
    Email: Yup.string().email().required("E-mail is Required"),
    Contact_No: Yup.number()
      .positive("Number should be Positive")
      .required("Phone Number Required"),
    Current_Address: Yup.string().required("Current Address Required"),
    Current_Club: Yup.string().required("Current Club Required"),
    // Recent_Photo: Yup.mixed().required("Please select Profile Image"),
    Country: Yup.string().required("Select a Country"),
    History: Yup.array(
      Yup.object().shape({
        Previous_Club: Yup.string().required("Previous Club Required"),
        Location: Yup.string().required("Select a Country"),
        Period: Yup.string().required("Engaged Period"),
      })
    ),
    Expected_Salary: Yup.number()
      .required("Expected Salary Required")
      .positive(),
    Videos: Yup.array(
      Yup.object().shape({
        Link: Yup.string().required("Youtube Link Required").url("Invalid URL"),
      })
    ),
  }),
  foreignPlayer: Yup.object().shape({
    Name: Yup.string()
      .required("Name is Required")
      .max(25, "Name shouldn't be max than 25 Characters"),
    Weight: Yup.number().required("Weight Required").positive().integer(),
    Height: Yup.number().required("Height Required").positive().integer(),
    Date_of_Birth: Yup.date().required("Date of Birth Required"),
    Email: Yup.string().email().required("E-mail is Required"),
    Contact_No: Yup.number()
      .positive("Number should be Positive")
      .required("Phone Number Required"),
    Current_Address: Yup.string().required("Current Address Required"),
    Current_Club: Yup.string().required("Current Club Required"),
    Country: Yup.string().required("Select a Country"),
    Preferred_Position: Yup.string().required("Prefered Position Requried"),
    Second_Preferred_Position: Yup.string().required(
      "Second Prefered Position Required"
    ),
    History: Yup.array(
      Yup.object().shape({
        Previous_Club: Yup.string().required("Previous Club Required"),
        Location: Yup.string().required("Select a Country"),
        Period: Yup.string().required("Engaged Period"),
      })
    ),
    Expected_Salary: Yup.number()
      .required("Expected Salary Required")
      .positive(),
    Videos: Yup.array(
      Yup.object().shape({
        Link: Yup.string().required("Youtube Link Required").url("Invalid URL"),
      })
    ),
  }),
  investor: Yup.object().shape({
    Name_of_Company: Yup.string().required("Enter a Company Name"),
    Contact_Person_Name: Yup.string().required("Enter a Your Name"),
    Designation: Yup.string().required("Enter a Designation"),
    Cell_Phone_NUmber: Yup.number().required("Enter a Cell Number"),
  }),
  sponsors: Yup.object().shape({
    Name_of_Company: Yup.string().required("Enter a Company Name"),
    Contact_Person_Name: Yup.string().required("Enter a Your Name"),
    Designation: Yup.string().required("Enter a Designation"),
    Cell_Phone_NUmber: Yup.number().required("Enter a Cell Number"),
    Email_Id: Yup.string().email("Email Required").required("Enter a Email ID"),
    Period: Yup.string().required("Select a Period of Time"),
  }),
  others: Yup.object().shape({
    Name_of_Company: Yup.string().required("Enter a Company Name"),
    Contact_Person_Name: Yup.string().required("Enter a Your Name"),
    Designation: Yup.string().required("Enter a Designation"),
    Cell_Phone_Number: Yup.number().required("Enter a Cell Number"),
    Email_Id: Yup.string().email("Email Required").required("Enter a Email ID"),
  }),
  partnerships: Yup.object().shape({
    Name_of_Company: Yup.string().required("Enter a Company Name"),
    Contact_Person_Name: Yup.string().required("Enter a Your Name"),
    Designation: Yup.string().required("Enter a Designation"),
    Cell_Phone_NUmber: Yup.number().required("Enter a Cell Number"),
    Email_Id: Yup.string().email("Email Required").required("Enter a Email ID"),
    Partnership: Yup.string().required("Select a Partnership Type"),
    Period: Yup.string().required("Select a Period of Time"),
  }),
};

export default contactUsValidations;
