import { useState } from "react";
import ButtonDropDown from "../../components/InputForms/ButtonDropDown";
import ContactUsConstants from "../../constants/contactUs";
import ForeignPlayerForm from "./misc/foreignPlayer";
import IconicPlayerForm from "./misc/iconicPlayer";
import InvestorForm from "./misc/investorForm";
import LocalPlayerForm from "./misc/localPlayer";
import OthersForm from "./misc/othersForm";
import PartnershipForm from "./misc/partnershipForm";
import SponsorForm from "./misc/sponsorForm";
import TechnicalTeamForm from "./misc/technicalTeam";

const ContactUs = () => {
  const [item, setItem] = useState(null);

  return (
    <div>
      {/* <div className="col-md-4 my-5"> */}
      {/* <h1 className="text-primary fs-3 fw-bold">Contact Us</h1> */}
      <div className="row row-relative mx-0">
        <div className="col-md-6 contact-background-color p-0">
          <img
            className="contact-image ms-3 ms-lg-5 mt-5"
            src="https://i.ibb.co/FhmvH8j/Pages.png"
            alt="Logo"
          />
          <h3 className="text-primary contact-form-fill">
            Select and fill up the form <br />
            and our Team will get back to you.
          </h3>
        </div>

        <div className="col-md-6 d-none d-md-block contact-background-color">
          <img
            className="contact-football-image"
            src="https://i.ibb.co/wNscJrX/contact.jpg"
            alt="Logo"
            height="436px"
          />
        </div>
      </div>

      {/* </div> */}
      <div className="container">
        <div className="form-wrapper row my-5">
          <div className="col-lg-3">
            <ButtonDropDown
              items={ContactUsConstants.subject}
              item={item}
              setItem={setItem}
            />
          </div>
          {item === "Technical Team" && <TechnicalTeamForm />}
          {item === "Foreign Player" && <ForeignPlayerForm />}
          {item === "Iconic Player" && <IconicPlayerForm />}
          {item === "Local Player" && <LocalPlayerForm />}
          {item === "Investor" && <InvestorForm />}
          {item === "Sponsor" && <SponsorForm />}
          {item === "Partnership" && <PartnershipForm />}
          {item === "Others" && <OthersForm />}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
