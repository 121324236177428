const HeadingContent = ({ title }) => {
  return (
    <div className="my-4">
      <h3 className="divider divider--left text-primary">
        <span className="divider__block text-title text-capitalize">
          {title}
        </span>
      </h3>
    </div>
  );
};

export default HeadingContent;
