import { Link } from "react-router-dom";
import HeadingContent from "../components/Global/headingContent";
import ArrowRight from "../components/Svg/ArrowRight";

const HomeContentWrapper = ({ children, ...props }) => {
  return (
    <div className="container-sm my-5 px-4 px-sm-0">
      <HeadingContent title={props.title} />
      <div className="team-wrapper align-content-center my-4 w-100 px-md-3">
        <div className="row">{children}</div>
      </div>
      <div className="w-100 text-end mb-3">
        <span>
          <Link className="view-more " to={props.link}>
            View More
          </Link>
          <ArrowRight />
        </span>
      </div>
    </div>
  );
};

export default HomeContentWrapper;
