import axios from "axios";

const uploadFile = async ({ file }) => {
  let formData = new FormData();
  formData.append("files", file);
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}/upload`,
      formData
    );
    return response.data;
  } catch (e) {
    return e;
  }
};

export default uploadFile;
