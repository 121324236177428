import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import TeamProfile from "../../components/Global/TeamProfile";
import OfficialProfile from "../../components/Global/OfficialProfile";
import axiosRequest from "../../helpers/axios";
import classNames from "../../helpers/classMerger";

function Team() {
  const [data, setData] = useState(null);
  const [active, setActive] = useState("all");
  const history = useHistory();
  const buttonHandler = (text) => {
    setActive(text);
    history.push("/team/#" + text);
  };
  const player_position = [
    "goalkeepers",
    "defenders",
    "midfielders",
    "forwards",
    "officials",
  ];
  useEffect(() => {
    window.scrollTo(0, 0);

    axiosRequest("/players").then((response) => {
      setData(response.data);
    });
    // eslint-disable-next-line
  }, []);
  return (
    <div className="mb-5">
      <div>
        <img
          src="https://i.ibb.co/18Jb1fR/TEAM1.jpg"
          alt="Our Team"
          className="img-responsive team-banner-image pt-3"
          width="100%"
        />
      </div>
      <div className="container">
        <form className="container-fluid d-flex flex-wrap">
          <div className="mt-4 row g-1">
            <div className="col my-3">
              <a
                href="#all"
                className={classNames(
                  "btn-primary border border-2  p-2 m-1",
                  active === "all"
                    ? "border-primary bg-transparent text-primary"
                    : "bg-primary text-white border border-0"
                )}
                onClick={() => buttonHandler("all")}
              >
                ALL
              </a>
            </div>

            {player_position.map((position) => (
              <div className="col my-3" key={position}>
                <a
                  href={"#" + position}
                  className={classNames(
                    "btn-primary border border-2  p-2 m-1",
                    active === position
                      ? "border-primary bg-transparent text-primary"
                      : "bg-primary text-white border border-0"
                  )}
                  onClick={() => buttonHandler(position)}
                >
                  {position.toUpperCase()}
                </a>
              </div>
            ))}
          </div>
        </form>

        <div id="goalkeepers" className="row g-2 mt-3">
          <h2 className="text-primary mt-4 mt-lg-5 mt-md-3 mt-sm-3 fs-3 fw-bold">
            GOALKEEPERS
          </h2>
          {data &&
            data
              .filter((goalkeeper) => goalkeeper.Position === "GoalKeeper")
              .map((team) => (
                <TeamProfile team={team} key={team.Avatar.hash} />
              ))}
        </div>

        <div id="defenders" className="row g-2 mt-3">
          <h2 className="text-primary mt-4 mt-lg-5 mt-md-3 mt-sm-3 fs-3 fw-bold">
            DEFENDERS
          </h2>

          {data &&
            data
              .filter((defenders) => defenders.Position === "Defender")
              .map((team) => (
                <TeamProfile team={team} key={team.Avatar.hash} />
              ))}
        </div>

        <div id="midfielders" className="row g-2 mt-3">
          <h2 className="text-primary mt-4 mt-lg-5 mt-md-3 mt-sm-3 fs-3 fw-bold">
            MIDFIELDERS
          </h2>
          {data &&
            data
              .filter((midfielders) => midfielders.Position === "MidFielder")
              .map((team) => (
                <TeamProfile team={team} key={team.Avatar.hash} />
              ))}
        </div>

        <div id="forwards" className="row g-2 mt-3">
          <h2 className="text-primary mt-4 mt-lg-5 mt-md-3 mt-sm-3 fs-3 fw-bold">
            FORWARDS
          </h2>

          {data &&
            data
              .filter((forwards) => forwards.Position === "Forwarder")
              .map((team) => (
                <TeamProfile team={team} key={team.Avatar.hash} />
              ))}
        </div>

        <div id="officials" className="row g-2 mt-3">
          <h2 className="text-primary mt-4 mt-lg-5 mt-md-3 mt-sm-3 fs-3 fw-bold">
            OFFICIALS
          </h2>
          {data &&
            data
              .filter(
                (officials) =>
                  officials.Position === "General_Manager" ||
                  officials.Position === "Manager" ||
                  officials.Position === "Head_Coach" ||
                  officials.Position === "Goal_Keeping_Coach" ||
                  officials.Position === "Assistant_Manager" ||
                  officials.Position === "Physiotherapist" ||
                  officials.Position === "Ball_Boy" ||
                  officials.Position === "Assistant"
              )

              .map((team) => (
                <OfficialProfile team={team} key={team.Avatar.hash} />
              ))}
        </div>
      </div>
    </div>
  );
}

export default Team;
