import React, { useEffect, useState } from "react";
import axiosRequest from "../../helpers/axios";
import showdown from "showdown";
import parse from "html-react-parser";
import splitPosition from "../../helpers/splitPosition";
var converter = new showdown.Converter();

function About() {
  const [data, setData] = useState(null);

  useEffect(() => {
    Promise.all([
      axiosRequest("/about-us"),
      axiosRequest("/board-members"),
    ]).then(function (results) {
      setData({
        aboutUs: results[0].data,
        boardMembers: results[1].data,
      });
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {data && (
        <img
          className="img-responsive main-image-resize"
          src={process.env.REACT_APP_API_URL + data.aboutUs.Featured_Image.url}
          alt="about us"
          width="100%"
        />
      )}
      <section>
        <div className="container">
          <div className="details my-4">
            {data && parse(converter.makeHtml(data.aboutUs.Description))}
          </div>
        </div>
      </section>

      {/* BOARD Members */}

      <div className="container mb-5">
        <h1 className="text-primary text-title fs-3 my-4">Board Members</h1>
        <div className="row align-items-center g-md-5">
          {data &&
            data.boardMembers.map((member) => (
              <div className="col-md-3" key={member.id}>
                <img
                  className="img-responsive image-resize "
                  src={process.env.REACT_APP_API_URL + member.Image.url}
                  alt="board member"
                />
                <div className="text-align-center ">
                  <p className="pt-3 boardmember-text m-0 fw-bold d-flex justify-content-center ">
                    {member.Name}
                  </p>
                  <p className=" d-flex boardmember-text justify-content-center">
                    {splitPosition(member.Position)}
                  </p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default About;
