import React, { useState, useEffect } from "react";
import axiosRequest from "../../helpers/axios";
import { useParams } from "react-router-dom";
import FacebookSvg from "../../components/Svg/FacebookSvg";
import TwitterSvg from "../../components/Svg/TwitterSvg";
import InstaSvg from "../../components/Svg/InstaSvg";
import { FacebookShareButton, TwitterShareButton } from "react-share";
function NewsDetailPage() {
  window.scrollTo(0, 0);

  const { id } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    axiosRequest("/posts/" + id).then((res) => setData(res.data));
    // eslint-disable-next-line
  }, []);
  const shareUrl = window?.location.href ?? "";

  return (
    <div>
      {data && (
        <div>
          <div className="container">
            <h2 className="text-primary pt-3">{data.Title}</h2>
          </div>

          <img
            className="img-responsive main-image-resize pt-3"
            src={process.env.REACT_APP_API_URL + data.Featured_Image.url}
            alt="News"
            width="100%"
          />
          <div className="container">
            <div className="pt-4 fw-bold fs-5 text-primary">
              Created on {data.News_Published_At}
              <p className="pt-2">Share news at:</p>
              <FacebookShareButton url={shareUrl}>
                <FacebookSvg
                  className="rounded-2 mx-2 head-icon cursor-pointer"
                  activeColor="red"
                  color="#1877F2"
                  width={26}
                />
              </FacebookShareButton>
              <TwitterShareButton url={shareUrl}>
                <TwitterSvg
                  className="rounded-2 mx-2 head-icon cursor-pointer"
                  activeColor="red"
                  color="#1877F2"
                  width={26}
                  // onClick={() => window.open("http://facebook.com")}
                />
              </TwitterShareButton>
              <InstaSvg
                className="rounded-2 mx-2 head-icon cursor-pointer"
                activeColor="red"
                color="#1877F2"
                width={26}
                onClick={() =>
                  window.open("https://www.instagram.com/butwal_lumbinifc/")
                }
              />
            </div>
            <p className="pt-4 fs-6">{data.Content}</p>
          </div>
        </div>
      )}
    </div>
  );
}

export default NewsDetailPage;
