import React, { useState, useEffect } from "react";
import moment from "moment";
import axiosRequest from "../../helpers/axios";
import nextMatch from "../../helpers/nextMatch";
import lastMatch from "../../helpers/matchesContainer";
import LeagueTableMain from "../../components/LeagueTable/LeagueTableMain";

// console.log(lastMatch());
function Fixtures() {
  const [upcoming, setUpcoming] = useState(null);
  const [standings, setStandings] = useState(null);
  const [matches, setMatches] = useState(null);
  const [ads, setAds] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);

    (async function getNextMatch() {
      setUpcoming(await nextMatch());
    })();
    (async function getlastMatch() {
      setMatches(await lastMatch());
    })();
    axiosRequest("/league-tables").then((response) =>
      setStandings(response.data)
    );
    axiosRequest("/advertisments").then((response) => setAds(response.data));

    // eslint-disable-next-line
  }, []);

  // const seasons = [2021, 2022];

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-lg-6 col-12 mt-3">
            <div className="card h-100 shadow p-2">
              <h2 className="text-primary fs-3 fw-bold">Last Match</h2>
              {matches && (
                <p>
                  {moment(
                    matches[matches.length - 1]?.Fixture.Date_Time
                  ).format("Do MMM")}{" "}
                  |{" "}
                  {moment(
                    matches[matches.length - 1]?.Fixture.Date_Time
                  ).format("dddd")}{" "}
                  |{" "}
                  {moment(
                    matches[matches.length - 1]?.Fixture.Date_Time
                  ).format("h:mm a")}{" "}
                  {/* |  {matches.Fixture.Venue} */}
                </p>
              )}
              <div
                className="
                row
                justify-content-center
                align-text-center align-items-center
              "
              >
                <div className="col-4 d-flex justify-content-center">
                  {matches && (
                    <ClubContainer
                      data={matches[matches.length - 1]?.Fixture.Home_Club}
                    />
                  )}
                </div>
                {matches && (
                  <div className="col-4 d-flex justify-content-center">
                    <h2 className="p-2">{matches[matches.length - 1]?.Home}</h2>
                    <h2 className="p-2">-</h2>
                    <h2 className="p-2">{matches[matches.length - 1]?.Away}</h2>
                  </div>
                )}
                <div className="col-4 d-flex justify-content-center">
                  {matches && (
                    <ClubContainer
                      data={matches[matches.length - 1]?.Fixture.Away_Club}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-12 mt-3">
            {upcoming !== null && upcoming !== undefined ? (
              <div className="card h-100 shadow p-2">
                <h2 className="text-primary fs-3 fw-bold">Upcoming Match</h2>
                {upcoming && (
                  <p>
                    {moment(upcoming.Date_Time).format("Do MMM")} |{" "}
                    {moment(upcoming.Date_Time).format("dddd")} |{" "}
                    {moment(upcoming.Date_Time).format("h:mm a")} |{" "}
                    {upcoming.Venue.Name}
                  </p>
                )}
                <div
                  className="
                row
                justify-content-center
                align-text-center align-items-center
              "
                >
                  <div className="col-4 d-flex justify-content-center">
                    {upcoming && <ClubContainer data={upcoming.Home} />}
                  </div>
                  <div className="col-4 d-flex justify-content-center">
                    <h2>VS</h2>
                  </div>
                  <div className="col-4 d-flex justify-content-center">
                    {upcoming && <ClubContainer data={upcoming.Away} />}
                  </div>
                </div>
              </div>
            ) : (
              <div className="card h-100 shadow p-2">
                <h2 className="text-primary fs-3 fw-bold">Upcoming Match</h2>
                <div className="d-flex justify-content-center mb-md-4">
                  <img
                    className="comming-soon"
                    src="https://image.freepik.com/free-vector/red-grunge-style-coming-soon-design_1017-26691.jpg"
                    alt="Coming up"
                  />
                </div>
              </div>

              // <div className="card shadow p-2">
              //   <h2 className="text-primary mb-5 fs-3 fw-bold">
              //     Upcoming Match
              //   </h2>
              //   <div
              //     className="
              //   row
              //   justify-content-center
              //   align-text-center align-items-center
              // "
              //   >
              //     <div className="col-4 d-flex justify-content-center">
              //       <img
              //         src="https://i.ibb.co/DMhnQQ5/Butwal-Lumbini-F-2.png"
              //         alt="dummy"
              //         className="dummy-image"
              //       />
              //     </div>
              //     <div className="col-4 d-flex justify-content-center">
              //       <h2>VS</h2>
              //     </div>
              //     <div className="col-4 d-flex justify-content-center">
              //       <img
              //         src="https://i.ibb.co/DMhnQQ5/Butwal-Lumbini-F-2.png"
              //         alt="dummy"
              //         className="dummy-image"
              //       />
              //     </div>
              //   </div>
              // </div>
            )}
          </div>
        </div>
      </div>

      <div className="container mt-4">
        <div className="row">
          <div className="col-md-12 col-lg-10 position-relative overflow-auto">
            {standings && <LeagueTableMain />}
          </div>
          {ads &&
            ads
              .filter((ads) => ads.section === "FixtureSection")
              .map((ads) => (
                <div className="col-md-2 d-none d-lg-block" key={ads.id}>
                  <img
                    className="position-absolute advertisment-image"
                    src={
                      process.env.REACT_APP_API_URL +
                      ads.Ads[0].advertisment.url
                    }
                    alt="Logo"
                  />
                </div>
              ))}
        </div>
      </div>
      <div className="container mt-4 mb-5">
        <div className="row">
          <div className="col-lg-10 col-md-12 mb-2">
            <div className="d-flex inline-block justify-content-end">
              <h2 className="text-primary me-2 fs-3 fw-bold">Season</h2>
              {/* <div className="dropdown">
                <button
                  className="btn btn-transparent border border-1 dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  2021
                </button>

                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuButton1"
                >
                  {seasons &&
                    seasons.map((season) => (
                      <li key={season}>
                        <div className="dropdown-item">{season}</div>
                      </li>
                    ))}
                </ul>
              </div> */}
            </div>
            <div className="matches-scroll">
              {matches &&
                matches.map((match) => (
                  <div
                    className="border-bottom border-1 border-dark"
                    key={match.id}
                  >
                    <p className="mt-3">
                      {moment(match.Fixture.Date_Time).format("Do MMM")} ,{" "}
                      {moment(match.Fixture.Date_Time).format("dddd")} ,{" "}
                      {moment(match.Fixture.Date_Time).format("h:mm a")}
                    </p>

                    <div className="row justify-content-center align-text-center">
                      <div className="col-4 d-flex justify-content-end">
                        <p className="mt-3 club-name-text pe-2 pe-sm-0">
                          {match.Fixture.Home_Club.Name}
                        </p>
                      </div>
                      <div className="col-4 d-flex flex-column">
                        <div
                          className="
                    d-flex
                    inline-block
                    align-item-start
                    justify-content-center
                  "
                        >
                          <img
                            className="p-2 logo"
                            src={
                              process.env.REACT_APP_API_URL +
                              match.Fixture.Home_Club.Logo.url
                            }
                            alt="Logo"
                          />
                          <p className="p-1 fs-3">{match.Home}</p>
                          <p className="p-1 fs-3">-</p>
                          <p className="p-1 fs-3">{match.Away}</p>
                          <img
                            className="p-2 logo"
                            src={
                              process.env.REACT_APP_API_URL +
                              match.Fixture.Away_Club.Logo.url
                            }
                            alt="Logo"
                          />
                        </div>
                        <p className="d-flex justify-content-center">
                          Rangashala, Kathmandu{/* {match.Fixture.Venue} */}
                        </p>
                      </div>
                      <div className="col-4 d-flex justify-content-start">
                        <p className="mt-3 club-name-text ps-2 ps-sm-0">
                          {match.Fixture.Away_Club.Name}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const ClubContainer = ({ data }) => {
  return (
    <div className="row w-100 h-100 d-flex flex-column align-items-center justify-content-center py-3 py-md-0">
      {data && (
        <img
          className="w-100 rounded-circle lastmatch-image"
          src={process.env.REACT_APP_API_URL + data.Logo.url}
          alt=""
        />
      )}
      {data && (
        <p className="fw-normal text-center my-2 my-md-3 text-uppercase sm-fs-6 small-text">
          {data.Name}
        </p>
      )}
    </div>
  );
};

export default Fixtures;
