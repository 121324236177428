const ArrowRight = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.9759 17.1042L18.9759 12.1042L13.9759 7.10425"
        stroke="#CA192F"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>

      <path
        d="M6.97589 17.1042L11.9759 12.1042L6.97589 7.10425"
        stroke="#CA192F"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </svg>
  );
};

export default ArrowRight;
