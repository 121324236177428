import React from "react";
const TwitterSvg = ({ className, height, width, onClick }) => {
  return (
    <React.Fragment>
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
      >
        <g clipPath="url(#clip0_382:3193)">
          <path
            d="M32 3.78868C30.7963 4.43489 29.5232 4.86439 28.22 5.06394C29.5934 4.06098 30.6202 2.47279 31.1058 0.600316C29.8202 1.53878 28.4136 2.19974 26.947 2.5546C26.0475 1.37176 24.8796 0.549349 23.5953 0.194304C22.311 -0.160742 20.9695 -0.0319981 19.7454 0.563794C18.5214 1.15959 17.4712 2.19487 16.7316 3.53503C15.9919 4.87519 15.597 6.45824 15.5981 8.07835C15.593 8.69657 15.644 9.31356 15.7501 9.91787C13.1403 9.76039 10.5868 8.92675 8.25668 7.47144C5.92654 6.01614 3.87219 3.97194 2.22793 1.47249C1.38375 3.24867 1.12215 5.35441 1.49651 7.3599C1.87088 9.3654 2.85299 11.1195 4.24239 12.2641C3.20438 12.2295 2.18832 11.8884 1.27983 11.2695V11.3575C1.28154 13.2221 1.80512 15.029 2.76244 16.4741C3.71976 17.9192 5.05237 18.9143 6.53594 19.2918C5.97469 19.4739 5.39626 19.5633 4.81586 19.5577C4.39909 19.5668 3.98275 19.5205 3.57404 19.4198C3.99804 21.0222 4.81518 22.4236 5.913 23.4308C7.01081 24.4381 8.33531 25.0018 9.70417 25.0443C7.38204 27.2794 4.5182 28.4919 1.5703 28.4882C1.04548 28.4924 0.520968 28.4553 0 28.377C2.99974 30.7561 6.49525 32.0147 10.0638 32.0005C22.1357 32.0005 28.7359 19.6929 28.7359 9.0245C28.7359 8.6674 28.7259 8.32309 28.7119 7.98078C30.0059 6.84105 31.1201 5.42044 32 3.78868V3.78868Z"
            fill="#03A9F4"
          />
        </g>
        <defs>
          <clipPath id="clip0_382:3193">
            <rect width="32" height="32" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </React.Fragment>
  );
};
export default TwitterSvg;
