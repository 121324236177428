import { useField } from "formik";
import classNames from "../../helpers/classMerger";
import ErrorLabel from "./misc/errorLabel";

const TextField = ({ label, prefix, onChange, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="d-flex flex-column my-2">
      <label className="neutral my-2" htmlFor={field.name}>
        {label} {props.required && " *"}
      </label>
      <div className="input-group">
        {prefix && (
          <span
            className="input-group-text rounded-0 bg-white custom-form-control border-end-0"
            id="basic-addon3"
          >
            {prefix}
          </span>
        )}
        <input
          className={classNames(
            "form-control custom-form-control",
            prefix ? "border-start-0" : ""
          )}
          {...props}
          {...field}
          autoComplete="off"
        />
      </div>
      {meta.error && <ErrorLabel message={meta.error} />}
    </div>
  );
};

export default TextField;
