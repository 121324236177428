import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axiosRequest from "../../helpers/axios";
import moment from "moment";

const News = () => {
  const [data, setData] = useState(null);
  const [count, setCount] = useState(null);
  // const [latestNews, setLatestNews] = useState(null);
  const [featureImage, setFeatureImage] = useState(null);
  const query = new URLSearchParams(useLocation().search);
  useEffect(() => {
    async function fetchData() {
      window.scrollTo(0, 0);
      const pageNumber = query.get("page") ?? 1;
      let limit = 12;
      let start = limit * (pageNumber - 1);
      const tempData = await axiosRequest(
        `/posts/?_limit=${limit}&_start=${start}&_sort=News_Published_At:DESC`
      );
      // setLatestNews(tempData.data.shift());
      setData(tempData.data);

      const tempCountData = await axiosRequest(`/posts/count`);
      setCount(tempCountData.data);
      const tempImg = await axiosRequest(
        `/posts/?_sort=News_Published_At:DESC`
      );
      setFeatureImage(tempImg.data.shift());
    }
    fetchData();
  }, []);

  console.log(featureImage);

  return (
    <div>
      <div className="row row-relative mx-0">
        <div className="col-lg-9 p-0">
          <span className="latest-news m-1 p-1 fs-5 ">Latest News</span>

          <img
            className="img-responsive main-image-resize card-img-top rounded-0"
            src={
              process.env.REACT_APP_API_URL + featureImage?.Featured_Image.url
            }
            alt="News"
            width="100%"
          />
        </div>

        <div className="col-lg-3 bg-primary border border-primary pt-3 advertisment-container">
          <span className="ribbon-box"></span>
          <p className="text-white mt-2 m-2 m-lg-4 fw-bold fs-4">
            {featureImage?.Title}
          </p>
          <p className="text-white mt-2 m-2 m-lg-4 news-para">
            {featureImage?.Content}
          </p>
          <Link
            to={{
              pathname: featureImage?.External_Link
                ? featureImage?.External_Link
                : "/news/" + featureImage?.id,
            }}
            target={featureImage?.External_Link ? "_blank" : "_self"}
            key={featureImage?.Featured_Image.hash}
            className="view-btn btn text-white text-decoration-underline ms-lg-3"
          >
            View More <i className="bi bi-chevron-double-right view"></i>
          </Link>
        </div>
      </div>

      {/* News Gallary */}
      <div className="container mt-5">
        <div className="row align-items-start g-4">
          {data &&
            data.map((pd) => (
              <div className="card-deck col-lg-3 col-md-4" key={pd.id}>
                <Link
                  to={{
                    pathname: pd?.External_Link
                      ? pd.External_Link
                      : "/news/" + pd.id,
                  }}
                  target={pd.External_Link ? "_blank" : "_self"}
                  key={pd.Featured_Image.hash}
                >
                  <div className=" card news-wrap rounded-0">
                    <img
                      className="img-responsive image-resize1 card-img-top rounded-0"
                      src={
                        process.env.REACT_APP_API_URL + pd.Featured_Image.url
                      }
                      alt="News"
                      width="100%"
                    />

                    <div className="row align-items-center card-body">
                      {data && (
                        <h2 className=" col-2 m-0 d-flex justify-content-center px-md-1 px-lg-2 text-primary  text-size1">
                          {moment(pd.News_Published_At).format("Do")}
                          <br />
                          {moment(pd.News_Published_At).format("MMM")}
                        </h2>
                      )}
                      <p className=" col-10 m-0 py-1 px-3 text-primary text-wrap border-start border-3 border-primary card-title text-size2">
                        {data && pd.Title}
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
        </div>
      </div>

      {/* News Pagination */}
      <div className="container mb-5">
        <div className="m-1 my-3" aria-label="Page navigation">
          <ul className="pagination w-100 d-flex justify-content-center justify-content-md-start">
            <li className="page-item p-2" title="Previous">
              <span className="page-link border-0" href="#">
                <i className="bi bi-chevron-left"></i>
              </span>
            </li>
            {count &&
              [...Array(parseInt((count / 9).toFixed(0)))].map(function (
                x,
                index
              ) {
                return (
                  <li
                    className="page-item  p-2"
                    key={index}
                    onClick={() =>
                      window.location.replace("?page=" + (index + 1))
                    }
                  >
                    <span className="page-link border border-primary border-1">
                      {index + 1}
                    </span>
                  </li>
                );
              })}

            <li className="page-item  p-2" title="Next">
              <span className="page-link border-0" href="#">
                <i className="bi bi-chevron-right"></i>
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default News;
