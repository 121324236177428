import { useField } from "formik";
import ErrorLabel from "./misc/errorLabel";

const TextArea = ({ label, ...props }) => {
  const [field, meta] = useField(props);

  return (
    <div className="d-flex flex-column my-2">
      <label className="neutral my-2" htmlFor={field.name}>
        {label} {props.required && " *"}
      </label>
      <textarea
        className="form-control custom-form-control remarks-boxsize"
        {...props}
        {...field}
        rows={6}
        placeholder="Hi, I would like to apply for the Team."
        autoComplete="off"
      ></textarea>
      {meta.error && <ErrorLabel message={meta.error} />}
    </div>
  );
};

export default TextArea;
