import HomeContentWrapper from "../../layout/HomeContentWrapper";
import TeamProfile from "../Global/TeamProfile";

const TeamViewer = ({ teams }) => {
  return (
    <HomeContentWrapper title="Team" link="/team">
      {teams.map((team) => (
        <TeamProfile team={team} key={team.Avatar.hash} />
      ))}
    </HomeContentWrapper>
  );
};

export default TeamViewer;
