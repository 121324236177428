import axios from "axios";
import { toast } from "react-toastify";
import bottomToTop from "./bottomToTop";

const formHelper = ({ values, setSubmitting, resetForm, url }) =>
  axios
    .post(`${process.env.REACT_APP_API_URL}/${url}`, values)
    .then(() => {
      toast.info("Successfully Submitted Form");
      setSubmitting(false);
      resetForm();
      bottomToTop();
    })
    .catch((e) => toast.error(e.toString()));

export default formHelper;
