import React from "react";
const InstaSvg = ({ className, height, width, onClick }) => {
  return (
    <React.Fragment>
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
      >
        <g clipPath="url(#clip0_382:3189)">
          <path
            d="M22 0H10C7.34784 0 4.8043 1.05357 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10L0 22C0 24.6522 1.05357 27.1957 2.92893 29.0711C4.8043 30.9464 7.34784 32 10 32H22C24.6522 32 27.1957 30.9464 29.0711 29.0711C30.9464 27.1957 32 24.6522 32 22V10C32 7.34784 30.9464 4.8043 29.0711 2.92893C27.1957 1.05357 24.6522 0 22 0V0ZM29 22C29 25.86 25.86 29 22 29H10C6.14 29 3 25.86 3 22V10C3 6.14 6.14 3 10 3H22C25.86 3 29 6.14 29 10V22Z"
            fill="url(#paint0_linear_382:3189)"
          />
          <path
            d="M16 8C13.8783 8 11.8434 8.84285 10.3431 10.3431C8.84285 11.8434 8 13.8783 8 16C8 18.1217 8.84285 20.1566 10.3431 21.6569C11.8434 23.1571 13.8783 24 16 24C18.1217 24 20.1566 23.1571 21.6569 21.6569C23.1571 20.1566 24 18.1217 24 16C24 13.8783 23.1571 11.8434 21.6569 10.3431C20.1566 8.84285 18.1217 8 16 8ZM16 21C14.6744 20.9984 13.4036 20.4711 12.4662 19.5338C11.5289 18.5964 11.0016 17.3256 11 16C11 13.242 13.244 11 16 11C18.756 11 21 13.242 21 16C21 18.756 18.756 21 16 21Z"
            fill="url(#paint1_linear_382:3189)"
          />
          <path
            d="M24.5998 8.46598C25.1885 8.46598 25.6658 7.98872 25.6658 7.39998C25.6658 6.81125 25.1885 6.33398 24.5998 6.33398C24.0111 6.33398 23.5338 6.81125 23.5338 7.39998C23.5338 7.98872 24.0111 8.46598 24.5998 8.46598Z"
            fill="url(#paint2_linear_382:3189)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_382:3189"
            x1="2.928"
            y1="29.072"
            x2="29.072"
            y2="2.928"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC107" />
            <stop offset="0.507" stopColor="#F44336" />
            <stop offset="0.99" stopColor="#9C27B0" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_382:3189"
            x1="10.344"
            y1="21.656"
            x2="21.656"
            y2="10.344"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC107" />
            <stop offset="0.507" stopColor="#F44336" />
            <stop offset="0.99" stopColor="#9C27B0" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_382:3189"
            x1="23.8458"
            y1="8.15398"
            x2="25.3538"
            y2="6.64598"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FFC107" />
            <stop offset="0.507" stopColor="#F44336" />
            <stop offset="0.99" stopColor="#9C27B0" />
          </linearGradient>
          <clipPath id="clip0_382:3189">
            <rect width={32} height={32} fill="white" />
          </clipPath>
        </defs>
      </svg>
    </React.Fragment>
  );
};
export default InstaSvg;
