import LeagueTable from "../LeagueTable/LeagueTable";
import FixtureBanner from "./FixtureBanner";

const FixtureLeagueWrapper = () => {
  return (
    <div className="container-sm my-md-1 my-lg-5 px-4 px-sm-0 pe-md-0 p-0">
      <div className="col-md-6 row m-0 align-content-center my-md-4 my-2 w-100 px-md-1">
        <div className="col-xl-6 p-0">
          <FixtureBanner />
        </div>
        <div className="col-xl-6 d-flex justify-content-xl-end pe-md-0 p-0">
          <div className="row p-0 m-0 col-xl-8 clear-table">
            <LeagueTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixtureLeagueWrapper;
