import MoveTopBotton from "../Buttons/MoveTopButton";
import SocialMedia from "./SocialMedia";
import Sponsers from "./Sponsors";

const Footer = () => {
  return (
    <>
      <div className="move-top pt-md-5">
        <MoveTopBotton />
      </div>
      <div className="footer position-relative">
        <div className="footer-bg">
          <Sponsers />
          <SocialMedia />
        </div>
      </div>
    </>
  );
};

export default Footer;
