import React from "react";
const FacebookSvg = ({ className, height, width, onClick }) => {
  return (
    <React.Fragment>
      <svg
        width={width}
        height={height}
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        onClick={onClick}
      >
        <path
          d="M31.9906 0H0.009375C0.00419733 0 0 0.00419733 0 0.009375V31.9906C0 31.9958 0.00419733 32 0.009375 32H31.9906C31.9958 32 32 31.9958 32 31.9906V0.009375C32 0.00419733 31.9958 0 31.9906 0Z"
          fill="#1877F2"
        />
        <path
          d="M22.225 20.625L22.9375 16H18.5V13C18.5 11.7375 19.1188 10.5 21.1063 10.5H23.125V6.5625C23.125 6.5625 21.2938 6.25 19.5438 6.25C15.8875 6.25 13.5 8.4625 13.5 12.475V16H9.4375V20.625H13.5V32H18.5V20.625H22.225Z"
          fill="#FEFEFF"
        />
      </svg>
    </React.Fragment>
  );
};
export default FacebookSvg;
