import classNames from "../../helpers/classMerger";

const ButtonDropDown = ({ items, item, setItem }) => {
  return (
    <div className="dropdown w-100 neutral">
      <label className="neutral my-2" htmlFor="">
        Subject *
      </label>
      <button
        className="btn dropdown-custom dropdown-toggle w-100 rounded-0 text-start"
        type="button"
        id="subject"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {item ? item : "Select a Form"}
      </button>
      <ul
        className="dropdown-menu dropdown-custom-menu"
        aria-labelledby="subject"
      >
        {items.map((value) => (
          <li key={value}>
            <span
              className={classNames(
                "dropdown-item",
                item === value ? "active" : ""
              )}
              onClick={() => setItem(value)}
            >
              {value}
            </span>
          </li>
        ))}
        {/* 
        <li>
          <span className="dropdown-item active">Technical Team</span>
        </li>
        <li>
          <span className="dropdown-item">Foreign Player</span>
        </li>
        <li>
          <hr className="dropdown-divider" />
        </li>
        <li>
          <span className="dropdown-item">Iconic Player</span>
        </li> */}
      </ul>
    </div>
  );
};

export default ButtonDropDown;
