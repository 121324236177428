import axiosRequest from "./axios";

const nextMatch = async () => {
  const fixtures = await (
    await axiosRequest("/fixtures/?_sort=Date_Time:ASC")
  ).data;
  const filter = fixtures.filter(
    (fixture) =>
      +new Date(fixture.Date_Time) > +Date.now() &&
      (fixture.Home.Name === "Butwal Lumbini F C" ||
        fixture.Away.Name === "Butwal Lumbini F C")
  );
  return filter[0];
};

export default nextMatch;
