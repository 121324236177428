import { useState } from "react";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import getYouTubeID from "get-youtube-id";
import HomeContentWrapper from "../../layout/HomeContentWrapper";

const GridGallery = ({ images }) => {
  const [openLightBox, setOpenLightBox] = useState({ state: false, index: 0 });
  return (
    <HomeContentWrapper title="Gallery" link="/gallery">
      {images.map((image, index) => (
        <div
          className="col-sm-6 col-lg-4 img-blend shadow-0 border-0 rounded-0 position-relative p-2 justify-content-between "
          onClick={() => setOpenLightBox({ state: true, index: index })}
          style={{ height: "271px" }}
          key={image.Featured_Image.hash}
        >
          <div className="scale-anim blend-darken bg-primary h-100 w-100 position-relative">
            <img
              className="w-100 h-100 grid-image"
              src={process.env.REACT_APP_API_URL + image.Featured_Image.url}
              alt=""
            />
            <div className="position-absolute top-0 w-100 h-100">
              <div className="content w-100 position-absolute bottom-0 d-flex flex-row justify-content-between align-items-center px-2 px-md-4">
                <p className="fs-5 text-white m-0">{image.Title}</p>
              </div>
            </div>
          </div>
        </div>
      ))}
      {openLightBox.state && (
        <div className="index-max">
          <ReactImageVideoLightbox
            data={images.map((data) => {
              return {
                url: data.Youtube_Link
                  ? "https://www.youtube.com/embed/" +
                    getYouTubeID(JSON.parse(data.Youtube_Link).url)
                  : process.env.REACT_APP_API_URL + data.Featured_Image.url,
                type: data.Youtube_Link ? "video" : "photo",
                altTag: data.Title,
              };
            })}
            startIndex={openLightBox.index}
            showResourceCount={true}
            onCloseCallback={() => setOpenLightBox({ state: false, index: 0 })}
            onNavigationCallback={(currentIndex) => {}}
          />
        </div>
      )}
    </HomeContentWrapper>
  );
};

export default GridGallery;
