import { useField } from "formik";
import ErrorLabel from "./misc/errorLabel";
import { CloudUploadIcon } from "@heroicons/react/outline";

const UploadInput = ({ label, onChange, file, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <div className="d-flex flex-column my-2">
      <label className="neutral my-2" htmlFor={field.name}>
        {label} {props.required && " *"}
      </label>
      <input
        className="form-control custom-form-control d-none"
        type="file"
        {...props}
        {...field}
        value={undefined}
        onChange={onChange}
      />
      <label
        className="w-100  form-control custom-form-control p-0 d-flex flex-row"
        htmlFor={props.name}
      >
        {!file && (
          <>
            <div className="row w-100 d-flex align-items-center px-4">
              <div className="col-12">PP-size</div>
              {/* <div className="col-6">35mm(W) x 45mm(H)</div> */}
            </div>
            <span>
              <CloudUploadIcon
                className="m-1"
                width={40}
                height={27}
                color="#313940"
              />
            </span>
          </>
        )}
        {file && (
          <span
            className="text-wrap m-1 w-100"
            style={{
              overFlow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              maxWidth: "85vw",
            }}
          >
            {file.name}
          </span>
        )}
      </label>

      {meta.error && <ErrorLabel message={meta.error} />}
    </div>
  );
};

export default UploadInput;
