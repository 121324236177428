import getYouTubeID from "get-youtube-id";
import { useParams } from "react-router-dom";
import axiosRequest from "../../helpers/axios";
import React, { useState, useEffect } from "react";
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { ReactComponent as Play } from "../../assets/svg/play-btn.svg";

function GalleryDetailPage() {
  const [data, setData] = useState(null);
  const [imageLightBox, setImageLightBox] = useState({
    state: false,
    index: 0,
  });
  const [videoLightBox, setVideoLightBox] = useState({
    state: false,
    index: 0,
  });
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);

    axiosRequest(`/galleries/${id}`).then((response) => setData(response.data));
    // eslint-disable-next-line
  }, []);
  return (
    <div>
      <div className="container mb-5">
        {data && <h2 className="text-primary m-4 mx-0"> {data.Title}</h2>}

        <div className="row g-2">
          {data &&
            data.Content_Image.map((image, index) => (
              <div
                className=" col-lg-3 col-md-4 col-sm-6 hover-fade gallary-image"
                key={image.id}
              >
                <div className="h-100" href="#" title="">
                  <img
                    src={`${process.env.REACT_APP_API_URL}${image.url}`}
                    alt="moments"
                    className="shadow-1-strong h-100 rounded w-100 gallary-image"
                    onClick={() =>
                      setImageLightBox({ state: true, index: index })
                    }
                  />
                </div>
              </div>
            ))}
          {data &&
            data.Videos.map((video, index) => (
              <div
                className=" col-lg-3 col-md-4 col-sm-6 hover-mask gallary-image"
                key={video.id}
                onClick={() => setVideoLightBox({ state: true, index: index })}
              >
                <div className="h-100" href="#" title="">
                  <img
                    src={`${JSON.parse(video.Youtube_Video).thumbnail}`}
                    alt="moments"
                    style={{ objectFit: "cover" }}
                    className="shadow-1-strong h-100 rounded w-100 gallary-videos"
                  />
                  <h2>
                    <span className="glyphicon glyphicon-search">
                      <Play width="87" height="87" viewBox="5 5 97 99" />
                    </span>
                  </h2>
                </div>
              </div>
            ))}
          {imageLightBox.state && (
            <ReactImageVideoLightbox
              data={
                data &&
                data.Content_Image.map((data) => {
                  return {
                    url: process.env.REACT_APP_API_URL + data.url,
                    type: data.Youtube_Video ? "video" : "photo",
                    altTag: data.Title,
                    key: id,
                  };
                })
              }
              startIndex={imageLightBox.index}
              showResourceCount={true}
              onCloseCallback={() =>
                setImageLightBox({ state: false, index: 0 })
              }
            />
          )}
          {videoLightBox.state && (
            <ReactImageVideoLightbox
              data={
                data &&
                data.Videos.map((data) => {
                  return {
                    url:
                      "https://www.youtube.com/embed/" +
                      getYouTubeID(JSON.parse(data.Youtube_Video).url),
                    type: data.Youtube_Video ? "video" : "photo",
                    altTag: data.Title,
                    key: id,
                  };
                })
              }
              startIndex={videoLightBox.index}
              showResourceCount={true}
              onCloseCallback={() =>
                setVideoLightBox({ state: false, index: 0 })
              }
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default GalleryDetailPage;
