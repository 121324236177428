import { useEffect, useState } from "react";
import CarouselSlider from "../../components/Carousel/Carousel";
import GridGallery from "../../components/Gallery/GridGallery";
import axiosRequest from "../../helpers/axios";
import TeamViewer from "../../components/Team/TeamViewer";
import LatestNews from "../../components/Global/LatestNews";
import FixtureLeagueWrapper from "../../components/FixtureBanner/FixtureLeagueWrapper";

const HomePage = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    Promise.all([
      axiosRequest("/home"),
      axiosRequest("/galleries?_limit=6"),
      axiosRequest("/players?_limit=4"),
      axiosRequest("/posts?_limit=6&_sort=News_Published_At:DESC"),
      axiosRequest("/advertisments"),
    ]).then(function (results) {
      setData({
        ...data,
        home: results[0].data,
        gallery: results[1].data,
        teams: results[2].data,
        news: results[3].data,
        advertisments: results[4].data,
      });
    });
    // eslint-disable-next-line
  }, []);

  return (
    <div className="">
      {data && <CarouselSlider images={data.home.Image_Slider} />}
      {data && <FixtureLeagueWrapper />}

      {data &&
        data.advertisments
          .filter((ads) => ads.section === "HomeFirstSection")
          .map((ads) => (
            <div className="container" key={ads.id}>
              <img
                className="img-responsive gallary-adv-image"
                src={
                  process.env.REACT_APP_API_URL + ads.Ads[0].advertisment.url
                }
                alt="Logo"
              />
            </div>
          ))}
      {data && <LatestNews news={data.news} />}
      {data && <GridGallery images={data.gallery} />}
      {data &&
        data.advertisments
          .filter((ads) => ads.section === "HomeSecondSection")
          .map((ads) => (
            <div className="container" key={ads.id}>
              <img
                className="img-responsive gallary-adv-image"
                src={
                  process.env.REACT_APP_API_URL + ads.Ads[0].advertisment.url
                }
              />
            </div>
          ))}
      {data && <TeamViewer teams={data.teams} />}
    </div>
  );
};

export default HomePage;
