import { useState } from "react";
import { Link } from "react-router-dom";
import { navData } from "../../constants/navData";
import FacebookSvg from "../Svg/FacebookSvg";
import InstaSvg from "../Svg/InstaSvg";
import NepalFlagSvg from "../Svg/NepalSvg";
import TwitterSvg from "../Svg/TwitterSvg";
import { useHistory } from "react-router-dom";
import logo from "../../assets/img/npl_flag.png"; // Tell webpack this JS file uses this image

const Navbar = () => {
  const history = useHistory();

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-light bg-primary p-0">
        <div className="container-fluid px-0 d-md-flex border-bottom border-1 border-white flex-lg-row">
          <div className="position-relative nav-top-wrapper w-100 border-md-start border-2 border-white border-start border-2 border-white">
            <div className="navbar-top bg-white text-secondary d-flex align-items-center justify-content-end border-bottom border-white w-100 py-1">
              <span className="me-3">Main Partner: </span>
              <img
                className="partner-img cursor-pointer"
                src="/images/ehub.png"
                alt="ehub_logo"
                onClick={() => window.open("https://ehubinternational.com/#")}
              />
            </div>
            <div className="navbar-fix d-flex bg-primary justify-content-end align-items-center d-lg-none">
              <button
                className="navbar-toggler border-0 shadow-0 outline-0 text-white"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navMobile"
                aria-controls="navMobile"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="navbar-logo d-flex justify-content-between position-absolute">
              <Link className="navbar-brand d-flex flex-row" to="/">
                <img
                  className="nav-img nav-brand img-fluid mx-1 mx-md-2 w-100 h-100"
                  src="/butwal-transparent.png"
                  alt="logo"
                />
              </Link>
            </div>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex justify-content-between w-100 px-md-5 text-capitalize pe-5">
                {navData.map((nav) => (
                  <li className="nav-item p-2" key={nav.link + "l"}>
                    <Link
                      className="nav-link"
                      data-toggle="collapse"
                      data-target=".navbar-collapse.show"
                      to={nav.link}
                    >
                      {nav.name}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
        <div className="collapse navbar-collapse d-lg-none" id="navMobile">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0 d-flex justify-content-between w-100 px-md-5 text-capitalize d-lg-none">
            {navData.map((nav) => (
              <li
                className="nav-item p-2 p-md-3 "
                key={nav.link + "m"}
                data-bs-toggle="collapse"
                data-bs-target="#navMobile"
                onClick={() => {
                  history.push(nav.link);
                }}
              >
                <span className="nav-link">{nav.name}</span>
              </li>
            ))}
          </ul>
        </div>
      </nav>
      <div className="w-100 d-flex align-items-center justify-content-end my-2 px-2">
        <FacebookSvg
          className="rounded-2 mx-2 head-icon cursor-pointer"
          activeColor="red"
          color="#1877F2"
          width={26}
          onClick={() =>
            window.open("https://www.facebook.com/Butwallumbinifc")
          }
        />

        <TwitterSvg
          className="rounded-2 mx-2 head-icon cursor-pointer"
          activeColor="red"
          color="#1877F2"
          width={26}
          onClick={() => window.open("https://twitter.com/blfcnepal")}
        />

        <InstaSvg
          className="rounded-2 mx-2 head-icon cursor-pointer"
          activeColor="red"
          color="#1877F2"
          width={26}
          onClick={() =>
            window.open("https://www.instagram.com/butwal_lumbinifc/")
          }
        />
        <div className="vertical-divider"></div>
        <img
          className="mx-2 head-icon cursor-pointer"
          activeColor="red"
          width={26}
          src={logo}
        />
      </div>
    </div>
  );
};

export default Navbar;
