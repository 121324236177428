const SubmitButton = ({ label, ...props }) => {
  return (
    <div className="top-btn d-flex align-items-center justify-content-start">
      <button
        className="btn btn-primary rounded-0 px-4 d-flex align-items-center px-5 my-4"
        type="submit"
        {...props}
      >
        {label}
      </button>
    </div>
  );
};

export default SubmitButton;
