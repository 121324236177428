import classNames from "../../helpers/classMerger";
const CarouselSlider = ({ images = [] }) => {
  return (
    <div
      id="carouselExampleIndicators"
      className="carousel slide"
      data-bs-ride="carousel"
    >
      <div className="carousel-indicators">
        {images.map((image, index) => (
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to={index}
            className={index === 0 ? "active" : ""}
            aria-current="true"
            aria-label={image.name}
            key={image.hash}
          />
        ))}
      </div>
      <div className="carousel-inner position-relative">
        {images.map((image, index) => (
          <div
            className={classNames(
              "carousel-item image-slider-item",
              index === 0 ? "active" : ""
            )}
            key={image.url}
          >
            <img
              src={process.env.REACT_APP_API_URL + image.url}
              className="d-block w-100 carousel-image fix-banner-wrapper"
              alt="carousel-img"
            />
          </div>
        ))}
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="prev"
      >
        <span className="ms-3 ms-md-0">
          <span className="carousel-control-icon-wrapper prev-icon">
            <span className="carousel-control-prev-icon" aria-hidden="true" />
          </span>
        </span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleIndicators"
        data-bs-slide="next"
      >
        <span className="me-3 me-md-0">
          <span className="carousel-control-icon-wrapper next-icon">
            <span className="carousel-control-next-icon" aria-hidden="true" />
          </span>
        </span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default CarouselSlider;
