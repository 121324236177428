import { Route, Switch } from "react-router-dom";
import About from "../pages/about/index";
import NewsDetailPage from "../pages/news/NewsDetailPage";
import Fixtures from "../pages/Fixture";
import Team from "../pages/team/index";
import Gallery from "../pages/Gallery";
import GalleryDetailPage from "../pages/Gallery/galleryDetails";
import News from "../pages/news/index";
import ContactUs from "../pages/ContactUs";
import HomePage from "../pages/Home";

const GenerateRouter = () => {
  return (
    <Switch>
      <Route path="/" component={HomePage} exact />
      <Route path="/about" component={About} exact />
      <Route path="/news" component={News} exact />
      <Route path="/news/:id" component={NewsDetailPage} />
      <Route path="/posts" component={News} exact />
      <Route path="/fixtures" component={Fixtures} exact />
      <Route path="/team" component={Team} exact />
      <Route path="/gallery" component={Gallery} exact />
      <Route path="/gallery/:id" component={GalleryDetailPage} exact />
      <Route path="/contact-us" component={ContactUs} exact />
      <Route path="*" component={ContactUs} />
    </Switch>
  );
};

export default GenerateRouter;
