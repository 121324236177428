const ContactUsConstants = {
  subject: [
    "Select a Form",
    "Technical Team",
    "Foreign Player",
    "Iconic Player",
    "Local Player",
    "Investor",
    "Sponsor",
    "Partnership",
    "Others",
  ],
};

export default ContactUsConstants;
