import { Link } from "react-router-dom";
import moment from "moment";
import HomeContentWrapper from "../../layout/HomeContentWrapper";

const LatestNews = ({ news }) => {
  return (
    <HomeContentWrapper title="Latest News" link="/news">
      {news.map((data) => (
        <Link
          to={{
            pathname: data.External_Link
              ? data.External_Link
              : "/news/" + data.id,
          }}
          className="px-2 col-md-6 scale-anim"
          target={data.External_Link ? "_blank" : "_self"}
          key={data.Featured_Image.hash}
        >
          <div className="d-flex row news-wrapper-media p-0 m-0 align-items-center my-2">
            <div className="col-4 col-md-3 p-0 overflow-hidden">
              <img
                src={process.env.REACT_APP_API_URL + data.Featured_Image.url}
                alt={data.Featured_Image.name}
                className="me-3 w-100 news-img-media w-100"
              />
            </div>

            <div className="col-8 col-md-9 d-flex flex-column align-content-center justify-content-center h-100 news-details-media px-6 px-md-4">
              <small className="fw-bold text-primary py-1">
                {moment(
                  data.News_Published_At
                    ? data.News_Published_At
                    : data.published_at
                ).format("Do MMM, YYYY")}
              </small>
              <p className="fw-bolder py-1 text-black">{data.Title}</p>
            </div>
          </div>
        </Link>
      ))}
    </HomeContentWrapper>
  );
};

export default LatestNews;
