const InitialValueForm = {
  technicalTeam: {
    Category: "Manager",
    Name: "",
    Date_of_Birth: "",
    Height: "",
    Weight: "",
    Country: "",
    Recent_Photo: "",
    Contact_No: "",
    Email: "",
    Current_Address: "",
    Current_Club: "",
    History: [
      {
        Previous_Club: "",
        Location: "",
        Period: "",
      },
    ],
    Videos: [
      {
        Link: "",
      },
    ],
    Expected_Salary: undefined,
    Remarks: "",
    // { Previous_Club: "", Country: "", Period: "" }
  },
  foreignPlayer: {
    Name: "",
    Date_of_Birth: "",
    Height: "",
    Weight: "",
    Country: "",
    Recent_Photo: "",
    Contact_No: "",
    Email: "",
    Current_Address: "",
    Prefered_Position: "",
    Second_Preferred_Position: "",
    Current_Club: "",
    History: [
      {
        Previous_Club: "",
        Location: "",
        Period: "",
      },
    ],
    Videos: [
      {
        Link: "",
      },
    ],
    Expected_Salary: "",
    Remarks: "",
  },
  investor: {
    Name_of_Company: "",
    Contact_person_Name: "",
    Designation: "",
    Cell_Phone_Number: "",
    Purpose: "",
  },
  sponsor: {
    Name_of_Company: "",
    Contact_Person_Name: "",
    Designation: "",
    Cell_Phone_NUmber: "",
    Email_Id: "",
    Period: "",
    Remarks: "",
  },
  partnership: {
    Name_of_Company: "",
    Contact_Person_Name: "",
    Designation: "",
    Cell_Phone_NUmber: "",
    Email_Id: "",
    Partnership: "",
    Period: "",
    Remarks: "",
  },
  others: {
    Name_of_Company: "",
    Contact_Person_Name: "",
    Designation: "",
    Cell_Phone_Number: "",
    Email_Id: "",
    Remarks: "",
  },

  techncialTeamCategory: [
    { key: "Manager", value: "Manager" },
    { key: "Head_Coach", value: "Head Coach" },
    { key: "Coach", value: "Coach" },
    { key: "GoalKeeper_Coach", value: "GoalKeeper Coach" },
    { key: "Physiotherapist", value: "Physiotherapist" },
    { key: "Analyst", value: "Analyst" },
  ],
  preferedPosition: [
    { key: "Striker", value: "Striker" },
    { key: "Center_Forward", value: "Center Forward" },
    { key: "Left_Winger", value: "Left Winger" },
    { key: "Right_Winger", value: "Right Winger" },
  ],
  secondPreferedPosition: [
    { key: "Striker", value: "Striker" },
    { key: "Center_Forward", value: "Center Forward" },
    { key: "Left_Winger", value: "Left Winger" },
    { key: "Right_Winger", value: "Right Winger" },
  ],
  Period_Sponsor: [
    { key: "One", value: 1 },
    { key: "Two", value: 2 },
    { key: "Three", value: 3 },
  ],
  Partnership_Type: [
    { key: "Media", value: "Media" },
    { key: "Hospitality", value: "Hospitality" },
    { key: "Hospital", value: "Hospital" },
  ],
};

export default InitialValueForm;
