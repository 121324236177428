import { ReactComponent as TopIcon } from "../../assets/svg/top.svg";
import React, { useState } from "react";

const MoveTopBotton = () => {
  const [showScroll, setShowScroll] = useState(false);

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400) {
      setShowScroll(true);
    } else if (showScroll && window.pageYOffset <= 400) {
      setShowScroll(false);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  window.addEventListener("scroll", checkScrollTop);
  return (
    <div className="container top-btn d-flex align-items-center justify-content-end">
      <button
        className="btn btn-primary rounded-0 px-4 d-flex align-items-center"
        onClick={scrollTop}
        // onClick={() => {
        //   document.body.scrollTop= 0 ; // For Safari
        //   document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        // }}
      >
        Back to Top
        <TopIcon className="ms-3" />
      </button>
    </div>
  );
};

export default MoveTopBotton;
